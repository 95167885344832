import React from 'react';

////////////////////////////////////////////////////////////////////
//if exe
//import SVG from './libs/react-inlinesvg-bono/react-inlinesvg-bono';
//else
import SVG from 'react-inlinesvg';
/////////////////////////////////////////////////////////////////////

//import { Tween, Timeline } from 'react-gsap';
//import { Back, ExpoScaleEase } from 'gsap';

//import ReactDOM from 'react-dom';

//import { otherComponentClass, andOnother } from './componentFileName';

export default class PollenButton extends React.Component {
  	
	constructor(props) {
	    super(props);

	    let thisStepPollen = this.props.thisStepPollen;		
		let thisPollenData = this.props.thisPollenData;
		let step = this.props.step;	

	    this.state = {	    	
	    	thisStepPollen:thisStepPollen,
	    	thisPollenData:thisPollenData,
	    	step:step,
	    	viewed:false,
	    };
	    
	    /*refs to children*/  
    	this.pollenButton = React.createRef();

    	/*methods to bind*/
	    this.buttonStateDelay = this.buttonStateDelay.bind(this);
	    
	}

/*	componentWillMount(){		
		let thisStepPollen = this.props.thisStepPollen;		
		let thisPollenData = this.props.thisPollenData;
		let step = this.props.step;	

		this.setState({ 
			thisPollenData: thisPollenData ,
			thisStepPollen: thisStepPollen ,
			step: step	
		});
		

	}*/
/*	componentWillReceiveProps(nextProps){}
	//shouldComponentUpdate(nextProps, nextState){}
	componentWillUpdate(nextProps, nextState){
		
		

	}*/

	buttonStateDelay(){
		const self = this;
		setTimeout(function(){			
			const val = (self.props.step === self.props.currentStep) ? 'block' : 'none';
			self.pollenButton.current.style.display = val;
		},1000);
	}


	render() {
		/*TODO: look for a react transition group tool to help manage delays and animation on state changes*/
		this.buttonStateDelay();

		let xTop = String(this.state.thisStepPollen.position[1]) + "%";
		let yLeft = String(this.state.thisStepPollen.position[0]) + "%";
		let zScale = String(this.state.thisStepPollen.position[2]);
		let shadow = (String(this.state.thisStepPollen.shadow))==="true" ? "shadow_floor" : "";

		let thumb = String(this.state.thisPollenData.thumb);

		return (
			
			<div style={{
					top:xTop, 
					left:yLeft,
					}} 
					className="pollenButtonHolder">
				<div style={{
					MozTransform: `scale(`+zScale+`)`,
					WebkitTransform: `scale(`+zScale+`)`,
					OTransform: `scale(`+zScale+`)`,
					msTransform: `scale(`+zScale+`)`,
					transform: `scale(`+zScale+`)`, 
					}}
					className={"pollenButton "+shadow}
					data-pollenid={this.props.dataPollenId} 
					ref={this.pollenButton} 
					step={this.state.step} 
					onClick={this.props.launchModalHandler}>
					{this.state.thisPollenData.name}
					<div className="thumb" style={{backgroundImage:`url(`+thumb+`)`}}></div>
					<div className="pollenPlus">
						<SVG
							    src="./images/pollen_plus.svg"
							    uniqueHash="pollenPlus"
							    uniquifyIDs={false}							    
							>					  
						</SVG>
					</div>
				</div>
			</div>
						
		)
		
	}

/*	componentDidMount() {		
		//fetch('https://api.mydomain.com').then(response => response.json()).then(data => this.setState({ data }));		
	}
	componentDidUpdate(prevProps,prevState){


	}
	componentWillUnmount(){}*/

}

PollenButton.propTypes = {
  //prop: PropTypes.string.isRequired
};

PollenButton.defaultProps = {};

//ReactDOM.render( <PollenButton />, document.getElementById('PollenButton') );

