import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function BottomBar({
  currentScreen,
  goToStep,
  hidePollenButtons,
  handleAudioMute,
  isMuted,
  screen,
  handleOnClick
}) {
  const onSpeakerButtonClick = () => {
    handleAudioMute(!isMuted)
  }
  return (
    <div className="bar_wrap">
      <Container fluid>
        <Row>
          <Col>
            <div className="next_wrap">
              {screen === "1" && <span>Begin Journey</span>}
              <div className="next_arrow">
                <img
                  className="arrow"
                  alt="Start Journey"
                  src="./images/mobile/caret_down.png"
                  onClick={() => { handleOnClick() }}
                // onClick={() => {
                //   hidePollenButtons();
                //   goToStep(currentScreen);
                // }}
                />
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="speaker">
              {isMuted ? (
                <img
                  alt="Speaker"
                  src="./images/speakerOff.svg"
                  onClick={onSpeakerButtonClick}
                />
              ) : (
                  <img
                    alt="Unmute"
                    src="./images/speaker.svg"
                    onClick={onSpeakerButtonClick}
                  />
                )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
