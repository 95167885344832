import React, { useState, useEffect, useRef } from "react";

import Header from "./mobileComponents/Header";
import Home from "./mobileComponents/Home";
// import InfoComponent from "./mobileComponents/InfoComponent";
import Footer from "./mobileComponents/Footer";
import Popup from "./mobileComponents/PopUp";
import { Howl } from "howler";
import BottomBar from "./mobileComponents/BottomBar";
import data from "../../data/data.json";
import { Object } from "globalthis/implementation";
let clickSound = {};
let clickSoundOut = {};

const MobileViewHome = ({ playAudio, isMuted, handleAudioMute }) => {
  const [state, setState] = useState({
    currentScreen: -1, //-1 is for displaying home screen section
    currentStepData: {},
    currentEnv: "0001", // forcing to load default envirnoment on page load
    videoPath: "",
    jsonDataObj: {},
    EnvData: {},
    selectedPollenData: [],
    selectedPollenDetails: {},
    videoPlayTime: 0,
    videoPauseTime: 0,
    logoShadow: true,
    showPollenButtons: false,
  });
  const [activeEnv, setActiveEnv] = useState(-1);
  const [wasLastMute, setWasLastMute] = useState(true);
  const activeState = useRef(state);
  const isMutedRef = useRef(null);
  const wasLastMuteRef = useRef(null);
  /*sound effects*/
  clickSound = new Howl({
    src: "./audio/interface/click.mp3",
    autoplay: false,
    loop: false,
    volume: 1,
  });
  clickSoundOut = new Howl({
    src: "./audio/interface/click-out.mp3",
    autoplay: false,
    loop: false,
    volume: 1,
  });
  const containerRef = useRef(null);
  const envRefs = [useRef(null), useRef(null), useRef(null)];

  const handleScroll = (e) => {
    if (!envRefs[0].current) return;
    if (envRefs[0].current.offsetTop - 100 > e.target.scrollTop) {
      setActiveEnv(-1);
      setCurrentStepData(-1);
    } else if (
      envRefs[0].current.offsetTop - 70 < e.target.scrollTop &&
      envRefs[1].current.offsetTop > e.target.scrollTop
    ) {
      setActiveEnv(0);
      setCurrentStepData(0);
    } else if (
      envRefs[1].current.offsetTop - 250 < e.target.scrollTop &&
      envRefs[2].current.offsetTop > e.target.scrollTop
    ) {
      setActiveEnv(1);
      setCurrentStepData(1);
    } else if (
      envRefs[2].current.offsetTop < e.target.scrollTop &&
      e.target.scrollTop + window.innerHeight <
      containerRef.current.offsetHeight - 50
    ) {
      setActiveEnv(2);
      setCurrentStepData(2);
    } else if (
      e.target.scrollTop + window.innerHeight >
      containerRef.current.offsetHeight - 50
    ) {
      if (!isMutedRef.current) {
        setCurrentStepData(3);
        handleAudioMute(true);
      }
      setActiveEnv(3);
    }
  };
  const setCurrentStepData = (activeStep) => {
    if (activeState.current.currentScreen !== activeStep && activeState && activeState.current.EnvData.steps && activeState.current.EnvData.steps.length > 0) {
      if (!wasLastMuteRef.current && activeStep !== 3) {
        setWasLastMute(true);
        handleAudioMute(false);
      }
      if (activeStep !== 3) {
        setState({
          ...activeState.current,
          currentScreen: activeStep,
          currentStepData: activeStep !== -1 ? activeState.current.EnvData.steps[0][activeStep] : {}
        });
      } else {
        setWasLastMute(isMutedRef.current);
      }
    }
  }
  useEffect(() => {
    // to fetch data from data.json from public folder
    let currentEnvData = data.env.find((ele) => ele.uid === state.currentEnv);

    setState({
      ...state,
      EnvData: currentEnvData,
      jsonDataObj: data,
      videoPath: data.menu[0]["video"], //setting homepage video path
      videoPosterPath: data.menu[0]["poster"], //setting homepage poster path
    });

    window.addEventListener("scroll", handleScroll, true);
    // fetch("data.json")
    //   .then((res) => res.json())
    //   .then((data) => {
    //     let currentEnvData = data.env.find(
    //       (ele) => ele.uid === state.currentEnv
    //     );
    //     setState({
    //       ...state,
    //       EnvData: currentEnvData,
    //       jsonDataObj: data,
    //       videoPath: data.menu[0]["video"], //setting homepage video path
    //       videoPosterPath: data.menu[0]["poster"], //setting homepage poster path
    //     });
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToStep = (currentStep) => { };

  const handlePollenModal = (pollenDetail) => {
    clickSound.play();
    setState({ ...state, selectedPollenDetails: pollenDetail });
  };

  const setShowPollenButtons = () => {
    setState({
      ...state,
      showPollenButtons: true,
    });
  };
  const handlePollenModelClose = () => {
    clickSoundOut.play();
    setState({ ...state, selectedPollenDetails: {} });
  };

  useEffect(() => {
    playAudio(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isMutedRef.current = isMuted;
  }, [isMuted, playAudio, state.currentStepData, state.jsonDataObj, state]);

  useEffect(() => {
    activeState.current = state;
    wasLastMuteRef.current = wasLastMute
  }, [state, wasLastMute]);

  const handleOnClick = () => {
    document.getElementById("screen0").scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="mobile_wrapper">
      <div ref={containerRef}>
        <Header
          activeEnv={activeEnv}
          EnvData={state.EnvData}
          jsonDataObj={state.jsonDataObj}
          goToStep={goToStep}
          handlePollenModal={handlePollenModal}
          logoShadow={state.logoShadow}
        />

        <div className="home_content home_content_bg">
          <h2>Virtual Botanical Walk</h2>
          <p>
            See and learn more about common allergens—trees, grasses, and
            weeds—in their natural environments.
          </p>
          <BottomBar
            goToStep={goToStep}
            currentScreen={state.currentScreen}
            isMuted={isMuted}
            isStepInView={activeEnv === -1}
            handleAudioMute={handleAudioMute}
            screen="1"
            handleOnClick={handleOnClick}
          />
        </div>

        {state.EnvData &&
          state.EnvData.steps &&
          state.EnvData.steps.length > 0 ?
          state.EnvData.steps[0].map((step, index) => {
            return (
              <Home
                key={index}
                envRef={envRefs[index]}
                index={index}
                activeEnv={activeEnv}
                currentScreen={state.currentScreen}
                goToStep={goToStep}
                currentStepData={step}
                jsonDataObj={state.jsonDataObj}
                videoPath={state.videoPath}
                videoPosterPath={state.videoPosterPath}
                videoPlayTime={state.videoPlayTime}
                videoPauseTime={state.videoPauseTime}
                handlePollenModal={handlePollenModal}
                showPollenButtons={state.showPollenButtons}
                setShowPollenButtons={setShowPollenButtons}
                handleAudioMute={handleAudioMute}
                isMuted={isMuted}
                logoShadow={state.logoShadow}
              />
            );
          }) : null
        }
        {/* <InfoComponent /> */}
        <Footer />

        {/* {!!state.currentStepData ? (
          <React.Fragment>
            <Home
              currentScreen={state.currentScreen}
              goToStep={goToStep}
              currentStepData={state.currentStepData}
              jsonDataObj={state.jsonDataObj}
              videoPath={state.videoPath}
              videoPosterPath={state.videoPosterPath}
              videoPlayTime={state.videoPlayTime}
              videoPauseTime={state.videoPauseTime}
              handlePollenModal={handlePollenModal}
              showPollenButtons={state.showPollenButtons}
              setShowPollenButtons={setShowPollenButtons}
              handleAudioMute={handleAudioMute}
              isMuted={isMuted}
              logoShadow={state.logoShadow}
            />
          </React.Fragment>
        ) : (
            <>
              {" "}
              <InfoComponent /> <Footer />{" "}
            </>
          )} */}

        {Object.keys(state.selectedPollenDetails).length ? (
          <Popup
            selectedPollenDetails={state.selectedPollenDetails}
            handlePollenModelClose={handlePollenModelClose}
            handleAudioMute={handleAudioMute}
            isMuted={isMuted}
          />
        ) : null}
      </div>
    </div>
  );
};
export default MobileViewHome;
