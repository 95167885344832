import React from "react";



export default function MobileButton(button_url) {

    return (
        <div>
                <div className="mobile_info_content">
                    <button className="mobile_btn_info" onClick={() => window.open(button_url.mobile_url, "_blank")}>
                        Visit Our Interactive Allergy Map
                    </button>
                </div>
        </div>
    );
}
