import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { TweenLite, Sine } from "gsap";
import PollenLayout from "./PollenLayout";
import BottomBar from "./BottomBar";
import "../styles/Home.scss";
import { scrollToStep } from "./../../helper";
const Home = (props, ref) => {
  const {
    currentScreen,
    goToStep,
    currentStepData,
    jsonDataObj,
    videoPath,
    videoPosterPath,
    videoPauseTime,
    handlePollenModal,
    showPollenButtons,
    setShowPollenButtons,
    isMuted,
    handleAudioMute,
    logoShadow,
    index,
    envRef,
    activeEnv,
  } = props;

  const videoRef = useRef(null);
  const loaderMobileIndication = useRef(null);
  const pollenRef = useRef(null);
  useEffect(() => {
    if (loaderMobileIndication.current) {
      loaderMobileIndication.current.style.display = "none";
    }
    TweenLite.to(loaderMobileIndication.current, 1, {
      x: "100%",
      delay: 1,
      ease: Sine.easeIn,
      onComplete: function () {
        console.log("complete");
        if (loaderMobileIndication.current) {
          loaderMobileIndication.current.style.display = "none";
        }
      },
    });
  }, []);
  useEffect(() => {
    console.log("VideoPath:::", videoPath, currentScreen);
    if (videoPath) {
      if (currentScreen === -1) {
        console.log("---------1");
        // delaying video for home screen
        videoRef.current.load();
        let videoDelayTimer = setTimeout(() => {
          if (videoRef.current.readyState >= 0) {
            clearInterval(videoDelayTimer);
            /*fade loader out*/
            // TweenLite.to(loaderMobileIndication.current, 1, {
            //   x: "100%",
            //   delay: 1,
            //   ease: Sine.easeIn,
            //   onComplete: function () {
            //     console.log("complete");
            //     if (loaderMobileIndication.current) {
            //       loaderMobileIndication.current.style.display = "none";
            //     }
            //   },
            // });
          }
        }, 100);
      } else {
        if (loaderMobileIndication.current) {
          loaderMobileIndication.current.style.display = "none";
        }
        let videoCurrentTime = 0;
        let currentStepIndex = 0;
        if (jsonDataObj.env[0].steps) {
          currentStepIndex = jsonDataObj.env[0].steps[0].findIndex(
            (s) => s.name === currentStepData.name
          );
          if (currentStepIndex > 0) {
            const prevVideoTime = parseInt(
              jsonDataObj.env[0].steps[0][currentStepIndex - 1].time
            );
            videoCurrentTime = prevVideoTime + 1;
          }
        }

        videoRef.current.load();
        if (videoRef.current.readyState >= 0) {
          videoRef.current.currentTime = videoCurrentTime;
          videoRef.current.play();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoPath, videoPauseTime]);
  const hidePollenButtons = () => {
    if (pollenRef.current) {
      pollenRef.current.classList.remove("pollenAnimate");
    }
  };

  const pauseVideoTime = () => {
    if (
      videoRef.current.currentTime > videoPauseTime &&
      videoPauseTime &&
      !videoRef.current.paused
    ) {
      setShowPollenButtons();
      videoRef.current.pause();
    }
  };

  return (
    <>
      <div id="loaderIndicationMobile" ref={loaderMobileIndication}>
        <img
          className="loader"
          src="./images/loading.gif"
          alt="loader animation"
        />
      </div>

      <div id={`screen${index}`} className="home_wrap" ref={envRef}>
        <div className="bg_shadow">
          <img alt="shadow" src="./images/mobile/shadow.png" />
        </div>
        <div className="video_holder">
          <img src={currentStepData.bgImg} alt="bg img" />
          <video
            style={{ display: "none" }}
            ref={videoRef}
            id="video"
            playsInline
            autoPlay={currentScreen === -1 ? true : false}
            loop={currentScreen === -1 ? true : false}
            className={currentStepData.name}
            muted
            // preload
            onTimeUpdate={pauseVideoTime}
            onEnded={() => {
              setShowPollenButtons();
            }}
            poster={videoPosterPath}>
            <source src={videoPath} type="video/mp4" />
          </video>
        </div>
        <Container fluid>
          {!!currentStepData.pollen && currentStepData.pollen.length && (
            <PollenLayout
              activeEnv={activeEnv}
              ref={pollenRef}
              title={currentStepData.name}
              currentStepPollenData={currentStepData.pollen}
              jsonDataObj={jsonDataObj}
              handlePollenModal={handlePollenModal}
              showPollenButtons={showPollenButtons}
              logoShadow={logoShadow}
            />
          )}
        </Container>
        <BottomBar
          goToStep={goToStep}
          currentScreen={currentScreen}
          isMuted={isMuted}
          handleAudioMute={handleAudioMute}
          hidePollenButtons={hidePollenButtons}
          handleOnClick={() => scrollToStep(index)}
        />
      </div>
    </>
  );
};

export default Home;
