import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { scrollToStep } from './../../helper'
export default function Header({
  EnvData,
  jsonDataObj,
  handlePollenModal,
  goToStep,
  activeEnv,
}) {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <header
      className={`header ${
        activeEnv === 0 || activeEnv === 3 ? "header_shadow" : "home_shadow"
        }`}>
      <div className="bg_shadow">
        <img alt="Shadow" src="./images/mobile/shadow.png" />
      </div>
      <div className="white_gr">
        <img alt="White Gradient" src="./images/mobile/white-gr.png" />
      </div>
      <Container fluid>
        <Row className="align-items-center">
          <Col xs="8">
            <div
              className="logo"
              onClick={() => {
                goToStep(-2);
              }}>
              {activeEnv !== 0 && activeEnv !== 3 ? (
                <img alt="logo" src={require("../../../images/logo.png")} />
              ) : (
                  <img
                    alt="White Logo"
                    className="logo_white logo_white_op"
                    src={require("../../../images/logo_white.png")}
                  />
                )}
            </div>
          </Col>
          <Col xs="4">
            <div className="menu_wrap">
              <div className="menu_btn_wrap" onClick={() => setNavOpen(true)}>
                <div className="menu_btn">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <h4>Menu</h4>
              </div>
              <nav className={`custom_nav ${navOpen ? "open_nav" : ""}`}>
                <Row className="menu_row align-items-center">
                  <Col xs="8">
                    <div className="logo">
                      <img alt="logo" src="./images/mobile/logo_white.png" />
                    </div>
                  </Col>
                  <Col xs="4">
                    <div
                      className="close_btn"
                      onClick={() => setNavOpen(false)}>
                      <img alt="Close" src="./images/mobile/cross_icon.png" />
                    </div>
                  </Col>
                </Row>
                <ul className="menu_list">
                  {!!EnvData.steps &&
                    EnvData.steps[0].map((step, index) => {
                      return (
                        <li key={index}>
                          <span
                            className="menu_title"
                            onClick={() => {
                              scrollToStep(index - 1);
                              setNavOpen(false);
                              goToStep(index - 1);
                            }}>
                            {step.name}{" "}
                          </span>
                          <ul className="sub_menu">
                            {!!step.pollen &&
                              step.pollen.map((pollen) => {
                                let pollenDetail = jsonDataObj.pollen.find(
                                  (poll) => poll.uid === pollen.uid
                                );
                                return (
                                  <li
                                    key={pollen.uid}
                                    onClick={() => {
                                      setNavOpen(false);
                                      goToStep(index - 1);
                                      document.body.classList.remove(
                                        "nav_open"
                                      );
                                      handlePollenModal(pollenDetail);
                                    }}>
                                    <div className="menu_link">
                                      <div className="icon">+</div>
                                      <div>{pollenDetail.name}</div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </li>
                      );
                    })}
                </ul>
              </nav>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
}
