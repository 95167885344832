import React from "react";

////////////////////////////////////////////////////////////////////
//if exe
//import SVG from './libs/react-inlinesvg-bono/react-inlinesvg-bono';
//else
import SVG from "react-inlinesvg";
/////////////////////////////////////////////////////////////////////

import "../MapMenu.scss";
import { TweenLite, ExpoScaleEase } from "gsap";
// eslint-disable-next-line
import { DrawSVGPlugin } from "./libs/gsap/bonus-files-for-npm-users/DrawSVGPlugin";
//import ReactDOM from 'react-dom';
import { Howl } from "howler";

export default class MapMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.myOnLoadHandler = this.myOnLoadHandler.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

    /*refs*/
    this.menu = React.createRef();
    this.menuButton = React.createRef();
    this.menuCloser = React.createRef();

    this.menuOpen = false;

    /*sound effects*/
    this.wooshSound = new Howl({
      src: "./audio/interface/woosh.mp3",
      autoplay: false,
      loop: false,
      volume: 1,
    });
    this.wooshSoundOut = new Howl({
      src: "./audio/interface/woosh-out.mp3",
      autoplay: false,
      loop: false,
      volume: 1,
    });
  }

  /*componentWillMount(){}
	componentWillReceiveProps(nextProps){}
	//shouldComponentUpdate(nextProps, nextState){}
	componentWillUpdate(nextProps, nextState){}*/

  myOnLoadHandler(src, svgID) {
    //console.log('svg loaded REAL');

    const self = this;

    const svg = document.getElementById(svgID);

    const items = svg.querySelectorAll(".item");

    for (var i = 0; i < items.length; i++) {
      items[i].addEventListener("click", function (e) {
        let step = this.getAttribute("data-step");
        let pollenID = this.getAttribute("data-pollenid");
        let menuClick = this.getAttribute("data-menuclick");
        clearActiveClass(step);

        //this.classList.add("visited");
        this.classList.add("active");
        //console.log('step = '+step);
        //console.log('pollenID = '+pollenID);
        self.props.gotoStepHandler(step, pollenID, menuClick);
      });
    }
    function clearActiveClass(step) {
      for (var i = 0; i < items.length; i++) {
        if (
          items[i].classList.contains("step") &&
          items[i].getAttribute("data-step") !== step
        ) {
          items[i].classList.remove("active");
        }
      }
    }
    /*init path*/
    const path = svg.querySelector(".path_grow");
    TweenLite.set(path, { drawSVG: "0%" });
  }

  openMenu() {
    //console.log('this.props.muted = '+this.props.muted);
    if (!this.props.muted) this.wooshSound.play();
    let self = this;
    this.menuOpen = true;
    TweenLite.to(this.menu.current, 0.5, {
      x: "0%",
      ease: ExpoScaleEase.easeOut,
      onStart: function () {
        self.props.onResize();
        self.menuButton.current.classList.add("hide");
        self.menu.current.style.display = "block";
      },
      onComplete: function () {
        self.menuCloser.current.classList.remove("hide");
      },
    });
  }

  closeMenu() {
    if (!this.props.muted && this.menuOpen) this.wooshSoundOut.play();
    let self = this;
    this.menuOpen = false;
    TweenLite.to(this.menu.current, 0.5, {
      x: "100%",
      ease: ExpoScaleEase.easeOut,
      onStart: function () {
        self.menuCloser.current.classList.add("hide");
      },
      onComplete: function () {
        self.menuButton.current.classList.remove("hide");
        self.menu.current.style.display = "none";
      },
    });
  }

  render() {
    return (
      <div>
        <div id="menu" ref={this.menu}>
          <div className="instr">
            <p> YOU MAY ALSO JUMP TO ANY POINT ON THE ROUTE.</p>
          </div>
          <div id="MapMenu">
            <SVG
              src="./images/map.svg"
              uniqueHash="mapMenu"
              uniquifyIDs={false}
              onLoad={(src) => {
                this.myOnLoadHandler(src, "MapMenu");
              }}
            ></SVG>
          </div>
          <a href="https://www.stagrallergy.com" target="_blank" rel="noopener noreferrer">
            <img
              className="stagr-logo"
              src="./images/logo_white.svg"
              alt="Stallergenes logo"
             />
				  </a>
          
        </div>
        {/*<div id="menuButton" ref={this.menuButton} onClick={this.openMenu}>=</div>*/}
        <div id="menuButton" ref={this.menuButton} onClick={this.openMenu}>
          <SVG
            src="./images/menu.svg"
            //src={require('./menu.svg')}
            uniqueHash="menuButton"
            uniquifyIDs={false}
            onError={(error) => console.log("svg error = " + error.message)}
          ></SVG>

          {/*<svg width="151.772" height="60" viewBox="0 0 151.772 60"><defs><clipPath id="clip-path__menuButton"><rect width="40" height="34.999" fill="none"></rect></clipPath></defs><g id="menu__menuButton" data-name="Group 374" transform="translate(-1818.228 -49)"><rect id="menuBox__menuButton" data-name="Rectangle 1068" width="60" height="60" transform="translate(1910 49)" fill="#069"></rect><g id="menuLineMask__menuButton" transform="translate(1920 62)" clipPath="url(#clip-path__menuButton)"><path id="menuLinesBlue__menuButton" data-name="Menu" d="M0,35V30H25v5ZM0,20V15H40v5ZM0,5V0H40V5Z" fill="#003D5C"></path><path id="menuLines__menuButton" data-name="Menu" d="M0,35V30H25v5ZM0,20V15H40v5ZM0,5V0H40V5Z" fill="#fff"></path></g><path id="menuText__menuButton" data-name="Path 18048" d="M17.28,0l-.024-10.08-4.944,8.3H10.56L5.64-9.864V0H1.992V-16.8H5.208L11.5-6.36,17.688-16.8H20.88L20.928,0ZM37.92-3.12V0H24.912V-16.8h12.7v3.12H28.776v3.648h7.8v3.024h-7.8V-3.12Zm18.5-13.68V0H53.232L44.856-10.2V0h-3.84V-16.8h3.216L52.584-6.6V-16.8ZM67.872.288a7.6,7.6,0,0,1-5.6-1.992,7.669,7.669,0,0,1-2-5.688V-16.8h3.888v9.264q0,4.512,3.744,4.512A3.509,3.509,0,0,0,70.68-4.116a5.09,5.09,0,0,0,.96-3.42V-16.8h3.84v9.408a7.669,7.669,0,0,1-2,5.688A7.6,7.6,0,0,1,67.872.288Z" transform="translate(1816.236 87.478)" fill="#069"></path></g></svg>*/}
        </div>
        <div
          id="menuCloser"
          className="hide"
          ref={this.menuCloser}
          onClick={this.closeMenu}
        >
          <span>CLOSE</span>&times;
        </div>
      </div>
    );
  }

  componentDidMount() {}
  /*componentDidUpdate(prevProps,prevState){


	}
	componentWillUnmount(){}*/
}

MapMenu.defaultProps = {};

//ReactDOM.render( <MapMenu/>, document.getElementById('app') );
