import React from "react";
import { Container } from "react-bootstrap";

export default function Home() {
  return (
    <div className="footer">
      <Container>
        <p>
          ©Stallergenes Greer 2022. <br /> All Rights Reserved. 100620H2702
          11.20
        </p>
      </Container>
    </div>
  );
}
