import ButtonToolbar from "react-bootstrap/ButtonToolbar";
//import Button from 'react-bootstrap/Button';
//import PollenModal from './components/PollenModal';
import Environment from "./components/Environment";
import React from "react";
//import logo from './logo.svg';
import "./App.scss";
import { TweenLite } from "gsap";
import ReactDOM from "react-dom";
import data from "./data/data.json";
// import $ from "jquery";

//import { otherComponentClass, andOnother } from './componentFileName';

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      envSelected: "",
      forceMuted: false,
      /*modalShow: false*/
    };

    /*refs to children*/
    this.envMenu = React.createRef();
    this.svgMenu_ref = React.createRef();
    this.loaderIndication = React.createRef();

    /*methods to bind*/
    /*this.modalClose = this.modalClose.bind(this);*/
    this.initData = this.initData.bind(this);
    this.initEnv = this.initEnv.bind(this);
    this.selectEnv = this.selectEnv.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.onResize = this.onResize.bind(this);
    /*this.enableFullscreenButton = this.enableFullscreenButton.bind(this);*/

    /*browser ratio scale*/
  }

  /* componentWillMount(){}
  componentWillReceiveProps(nextProps){}
  //shouldComponentUpdate(nextProps, nextState){}
  componentWillUpdate(nextProps, nextState){}*/

  onResize() {
    let w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    let h = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );

    const envHolder = document.getElementById("envHolder");
    const menuButton = document.getElementById("menuButton");
    const menuCloser = document.getElementById("menuCloser");
    const homeMenuHolder = document.getElementById("homeMenuHolder");
    const menu = document.getElementById("menu");
    const loaderIndication = document.getElementById("loaderIndication");

    if (w > (h * 3) / 2 && menuButton) {
      TweenLite.set([envHolder, homeMenuHolder, menu, loaderIndication], {
        scale: (h * 1.5) / w,
        transformOrigin: "top center",
      });
      //console.log('w - envHolder.getBoundingClientRect().width',w - envHolder.getBoundingClientRect().width);
      //console.log('envHolder.getBoundingClientRect().width',envHolder.getBoundingClientRect().width);
      //console.log('w ',w );
      //if(homeMenuHolder.style.display === 'none'){
      menuButton.style.right =
        (w - envHolder.getBoundingClientRect().width) / 2 + 80 + "px";
      menuCloser.style.right =
        (w - envHolder.getBoundingClientRect().width) / 2 + 80 + "px";
      //}
    } else if (menuButton) {
      TweenLite.set(envHolder, { scale: 1, transformOrigin: "top center" });
      menuButton.style.right = "6%";
    }
  }

  toggleFullscreen() {
    //console.log('fullscreen toggle');
  }

  initData() {
    setTimeout(() => {
      this.setState({
        data: data,
      });
      this.selectEnv("0001");
    }, 1000);

    // // console.log("self.state.data.pollen = " + this.responseText);
    // //console.log("self.state.data.env = "+self.state.data.env.length);

    // /*FORCE TO LOAD THE FIRST ENVIRONMENT*/

    // let self = this;
    // let xmlhttp = new XMLHttpRequest();
    // xmlhttp.onreadystatechange = function () {
    //   if (this.readyState === 4 && this.status === 200) {
    //     //this.data = JSON.parse(this.responseText);
    //     console.log("Res::::::", JSON.parse(this.responseText));
    //     self.setState({
    //       data: JSON.parse(this.responseText),
    //     });
    //     // console.log("self.state.data.pollen = " + this.responseText);
    //     //console.log("self.state.data.env = "+self.state.data.env.length);

    //     /*FORCE TO LOAD THE FIRST ENVIRONMENT*/
    //     self.selectEnv("0001");
    //   }
    // };
    // xmlhttp.open("GET", "data.json", true);
    // xmlhttp.send();
  }

  selectEnv(env) {
    this.setState({ envSelected: env });
    this.initEnv(env);

    if (this.envMenu.current) {
      this.envMenu.current.style.display = "none";
    }
  }

  initEnv(env) {
    const mountLocation = document.getElementById("envHolder");
    mountLocation && ReactDOM.unmountComponentAtNode(mountLocation);
    if (Object.keys(this.state.data).length) {
      var thisEnvData = this.state.data.env.find(
        (env_obj) => env_obj.uid === env
      );
      var pollenData = this.state.data.pollen;
      var homeVideoPath = this.state.data.menu[0].video;
      var homeVideoPosterPath = this.state.data.menu[0].poster;
    }
    // console.log('homeVideoPath = '+homeVideoPath);
    mountLocation &&
      ReactDOM.render(
        <Environment
          theData={this.state.data}
          thisEnvData={thisEnvData}
          pollenData={pollenData}
          mountlocation={mountLocation}
          homeVideoPath={homeVideoPath}
          homeVideoPosterPath={homeVideoPosterPath}
          svgMenu_ref={this.svgMenu_ref}
          loaderIndication={this.loaderIndication}
          onResize={this.onResize}
          forceMuted={this.state.forceMuted}
        />,
        mountLocation
      );
    this.onResize();
  }

  /*modalClose(e){
    this.setState({ modalShow: false });
  }*/

  componentWillUnmount() {
    const mountLocation = document.getElementById("envHolder");
    ReactDOM.unmountComponentAtNode(mountLocation);
  }

  render() {
    return (
      <div className="App">
        <div id="envHolder"></div>
        <div id="homeMenuHolder"></div>
        <div id="menuHolder" ref={this.svgMenu_ref}></div>
        <div id="loaderIndication" ref={this.loaderIndication}>
          <img
            className="loader"
            src="./images/loading.gif"
            alt="loader animation"></img>
        </div>
        {/*<div id="fullscreenToggle" data-toggle-fullscreen onClick={this.toggleFullscreen}></div>*/}

        <ButtonToolbar id="envMenu" ref={this.envMenu}>
          {/*TODO: read data to make buttons*/}
          {/*<Button onClick={() => this.selectEnv("0001")} >
            Environment 1
          </Button>

          <Button onClick={() => this.selectEnv("0002")} >
            Environment 2
          </Button>

          <Button onClick={() => this.selectEnv("0003")} >
            Environment 3
          </Button>*/}

          {/* <Button variant="primary"onClick={() => this.setState({ modalShow: true })} >
            Launch vertically centered modal
          </Button>*/}
        </ButtonToolbar>

        {/*<PollenModal show={this.state.modalShow} onHide={this.modalClose} />*/}
      </div>
    );
  }

  componentDidMount() {
    //fetch('https://api.mydomain.com').then(response => response.json()).then(data => this.setState({ data }));
    this.initData();

    //this.enableFullscreenButton();

    const winWidth = window.innerWidth;
    if (winWidth < 768) {
      // alert("This website is not fully compatible with mobile phones.");
    }

    {
      const self = this;
      window.addEventListener("resize", function () {
        self.onResize();
      });
    }

  }

  enableFullscreenButton() {
    if (!Element.prototype.requestFullscreen) {
      Element.prototype.requestFullscreen =
        Element.prototype.mozRequestFullscreen ||
        Element.prototype.webkitRequestFullscreen ||
        Element.prototype.msRequestFullscreen;
    }

    if (!document.exitFullscreen) {
      document.exitFullscreen =
        document.mozExitFullscreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen;
    }

    if (!document.fullscreenElement) {
      Object.defineProperty(document, "fullscreenElement", {
        get: function () {
          return (
            document.mozFullScreenElement ||
            document.msFullscreenElement ||
            document.webkitFullscreenElement
          );
        },
      });

      Object.defineProperty(document, "fullscreenEnabled", {
        get: function () {
          return (
            document.mozFullScreenEnabled ||
            document.msFullscreenEnabled ||
            document.webkitFullscreenEnabled
          );
        },
      });
    }

    document.getElementById("fullscreenToggle").addEventListener(
      "click",
      function (event) {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          document.documentElement.requestFullscreen();
        }
      },
      false
    );



  }





/*  componentDidUpdate(prevProps,prevState){}
componentWillUnmount(){}*/
}

App.propTypes = {
  //prop: PropTypes.string.isRequired
};

App.defaultProps = {};

//ReactDOM.render( <App />, document.getElementById('App') );
