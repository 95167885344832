import Modal from "react-bootstrap/Modal";
//import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

import "../PollenModal.scss";

import Parser from "html-react-parser";
//import {Howl} from 'howler';
//import ReactDOM from 'react-dom';
import AudioPlayer from "react-h5-audio-player";
import { Howl } from "howler";
// import InfoComponent from "./InfoComponent";
import Button from './Button';
//import { otherComponentClass, andOnother } from './componentFileName';

export default class PollenModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    /*refs to children*/
    //this.SomeChildClass = React.createRef();

    /*methods to bind*/
    //this.methodNameHere = this.methodNameHere.bind(this);

    /*sound effects*/
    this.clickSound = new Howl({
      src: "./audio/interface/click.mp3",
      autoplay: false,
      loop: false,
      volume: 1,
    });

    this.clickSoundOut = new Howl({
      src: "./audio/interface/click-out.mp3",
      autoplay: false,
      loop: false,
      volume: 1,
    });
  }

  /*componentWillMount(){}
	componentWillReceiveProps(nextProps){}
	//shouldComponentUpdate(nextProps, nextState){}
	componentWillUpdate(nextProps, nextState){}*/

  render() {
    const pollenDataNode = this.props.pollendatanode;
    const pollenDataNodeImages = this.props.pollendatanode.images.map(
      (image, index) => {
        return (
          <Col key={index} xl="3" lg="6" md="12">
            <img src={image} alt="pollen" className="pollen-image"></img>
          </Col>
        );
      }
    );

    const audioPlayer = () => (
      <div className="audio-holder">
        <p className="audio-title">Hear more about {pollenDataNode.name}</p>
        <AudioPlayer
          className="audio-player"
          src={pollenDataNode.audio}
          onPlay={(e) => (!this.props.muted ? this.clickSound.play() : null)}
          onPause={(e) =>
            !this.props.muted ? this.clickSoundOut.play() : null
          }
        // other props here
        />
      </div>
    );
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Container>
          <Row>
            <Col sm={12}>
              <h1>{pollenDataNode.name}</h1>
            </Col>
          </Row>

          <Row className="pt-5">
            <Col sm={5} className="left pr-5">
              <p className="head">Genus/species:</p>
              <p className="copy">{Parser(pollenDataNode.genus_species)}</p>

              <div
                style={
                  pollenDataNode.synonyms !== ""
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <p className="head">Synonym:</p>
                <p className="copy">{Parser(pollenDataNode.synonyms)}</p>
              </div>

              <p className="head">Family:</p>
              <p className="copy">{pollenDataNode.family}</p>

              <p className="head">Distribution:</p>
              <p className="copy">{pollenDataNode.distribution}</p>
              <p className="copy">{pollenDataNode.distributionDetails}</p>

              {audioPlayer()}

            </Col>
            <Col sm={7} className="right">
              <p>{Parser(pollenDataNode.comments)}</p>
              <div>
              <Button url={pollenDataNode.button_url}/>
              </div>
            </Col>
          </Row>
          <Row className="pt-5 images-holder">{pollenDataNodeImages}</Row>
        </Container>
        <div className="closer" onClick={this.props.onHide}>
          <span>CLOSE</span>&times;
        </div>
      </Modal>
    );
  }

  /*	componentDidMount() {
		//fetch('https://api.mydomain.com').then(response => response.json()).then(data => this.setState({ data }));
	}
	componentDidUpdate(prevProps,prevState){}
	componentWillUnmount(){}
*/
}

Modal.propTypes = {
  //prop: PropTypes.string.isRequired
};

Modal.defaultProps = {};

//ReactDOM.render( <PollenModal />, document.getElementById('PollenModal') );
