import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

export default function ControlledCarousel({ images }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="carousel_wrap">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {images &&
          images.length &&
          images.map((img, index) => {
            return (
              <Carousel.Item key={index}>
                <img className="d-block w-100" src={img} alt={index} />
              </Carousel.Item>
            );
          })}
      </Carousel>
    </div>
  );
}
