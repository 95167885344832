// import { configureRequestOptionsFromUrl } from "builder-util-runtime";
import React from "react";



const PollenCard = (props) => {
  const { title, imgPath, handlePollenModal, pollenDetails } = props;
  return (
    <div className="card_wrap">
      <div
        className="thumb"
        style={{ backgroundImage: `url(${imgPath})` }}
        onClick={() => handlePollenModal(pollenDetails)}></div>
      <div
        className={"pollenButton "}
        onClick={() => handlePollenModal(pollenDetails)}>
        <span
          dangerouslySetInnerHTML={{
            __html: title,
          }}></span>
        <div className="pollenPlus">
          <img alt="plus" src="./images/mobile/plus.svg" />
        </div>
      </div>
    </div>
  );
};

export default PollenCard;
