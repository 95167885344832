import React from 'react';
import '../HomeMapMenu.scss';
//import { TweenLite,ExpoScaleEase } from 'gsap';
import ReactDOM from 'react-dom';
//import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//import { Tween/*, Timeline*/ } from 'react-gsap';
//import { TweenMax, TweenLite, Back, ExpoScaleEase } from 'gsap';


export default class HomeMapMenu extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    };

	   /*methods*/
	   this.menuItem_click = this.menuItem_click.bind(this);


	    /*refs*/
	   this.homeMenu = React.createRef();
	   this.homeVid = React.createRef();

	   this.homeVideoPath = String(this.props.homeVideoPath);
	   this.homeVideoPosterPath = String(this.props.homeVideoPosterPath);
	   this.thisEnvData = this.props.thisEnvData;
	}


	/*componentWillMount(){}
	componentWillReceiveProps(nextProps){}
	//shouldComponentUpdate(nextProps, nextState){}
	componentWillUpdate(nextProps, nextState){}*/

	menuItem_click(e){
		let step = e.target.getAttribute("step");
		const self = this;
		//console.log('MENU step = '+step);
		this.props.gotoStepHandler(String(step),"",true);

		const homeMenuHolder = document.getElementById('homeMenuHolder');

		/*slight delay to allow the video to get to its from frame*/
		setTimeout(function(){
			homeMenuHolder.style.display = "none";
			self.props.onResize();
			ReactDOM.unmountComponentAtNode(homeMenuHolder);
			//homeMenuHolder.parentNode.removeChild(homeMenuHolder);

		},100);

	}

	render() {

		const menuButtons_map = this.thisEnvData.steps[0].map((step, index) => {
			return (
				<Col sm={3} className="col" key={index}>
					<div
						className="box-button"
						style={{backgroundImage:`url(`+step.thumb+`)`}}
						step={index+1}
						onClick={this.menuItem_click}
						data-menuclick="true">
						<p>{step.name}</p>
					</div>
				</Col>
			)
	    });
		return (

			<div id="homeMenu" ref={this.homeMenu}>

				<div className="video-holder">
					<video id="homeVideo" ref={this.homeVid} autoPlay loop muted playsInline className="" poster={this.homeVideoPosterPath}>
                    	<source src={this.homeVideoPath} type="video/mp4"/>
                	</video>
				</div>
				<div className="content-holder">
					<Container>
					  <Row>
					    <Col>
					    	<h1 className="white drop-shadow">Virtual Botanical Walk</h1>
					    	<h3 className="white drop-shadow">See and learn more about common allergens—trees, grasses, and weeds—in their natural environments.</h3>
					    </Col>

					  </Row>
					  <Row className="mt-5 menu-boxes">
					    <Col sm={3} className="col px-0" key="-1">
					    		{/*<Tween from={{ x: "-100", autoAlpha:0, delay:1 }}>*/}
						    	<div
						    		className="odd-ball"
						    		step={1}
									onClick={this.menuItem_click}
									data-menuclick="true">
							    	<Row className="box-button no-border instr px-0 py-0 py-md-3 mx-0 align-items-center">
								    	<Col sm={3} className="h-50"><p>Begin journey</p></Col>
								    	<Col sm={3} className="h-100"><div className="play"></div></Col>
								    	<Col sm={6} className="h-50 pl-4 pl-lg-3"><p>Or jump to a specific location:</p></Col>
							    	</Row>
						    	</div>
						    	{/*</Tween>*/}

					    </Col>

					    {menuButtons_map}

					    {/*<Col sm={3} className="col"><div className="box-button e1"><p>FOREST</p></div></Col>
					    <Col sm={3} className="col"><div className="box-button e2"><p>PASTURE</p></div></Col>
					    <Col sm={3} className="col"><div className="box-button e3"><p>ROADSIDE</p></div></Col>*/}
					  </Row>

					</Container>
				</div>
				<div className="top-gradient"></div>
				<div
				id="speaker"
				className={this.props.muted ? 'muted':'unmuted'}
				ref={this.props.speaker}
				onClick={this.props.toggleMute}>
				<img
					className="speakerBtn on"
					src="./images/speaker.svg"
					alt="speaker icon button on"></img>
				<img
					className="speakerBtn off"
					src="./images/speakerOff.svg"
					alt="speaker icon button off"></img>
				</div>
				<a href="https://www.stagrallergy.com" target="_blank" rel="noopener noreferrer">
					<img className="stagr-logo" src="./images/logo.svg" alt="Stallergenes logo" ></img>
				</a>
				<div id="footer">&copy;Stallergenes Greer 2022. All Rights Reserved.  073119H2617 8.19</div>
			</div>

		)
	}

	componentDidMount() {
		const self = this;

		let b = setInterval(()=>{

	        if(self.homeVid.current.readyState >= 4){
	            clearInterval(b);

	        }
	    },100);

		//fetch('https://api.mydomain.com').then(response => response.json()).then(data => this.setState({ data }));
	}
	/*componentDidUpdate(prevProps,prevState){


	}
	componentWillUnmount(){
		//const homeMenuHolder = document.getElementById('homeMenuHolder');
		//homeMenuHolder.style.display = "none";
	}*/
}

HomeMapMenu.defaultProps = {};

//ReactDOM.render( <MapMenu/>, document.getElementById('app') );

