import React from "react";



export default function Button(url) {
    return (
        <div>
                <div className="info_content">
                    <button className="btn_info" onClick={() => window.open(url.url, "_blank")}>
                        Visit Our Interactive Allergy Map
                    </button>
                </div>
        </div>
    );
}
