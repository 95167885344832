import React, { useState } from "react";
import { Container } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";

import Carousel from "./Carousel";
import MobileButton from "./MobileButton";

export default function Popup({
  selectedPollenDetails,
  handlePollenModelClose,
  handleAudioMute,
  isMuted
}) {
  const {
    name,
    mobileName,
    genus_species,
    synonyms,
    family,
    distributionMobileDetails,
    images,
    audio,
    mobileComments,
    distributionMobile,
    button_url,
  } = selectedPollenDetails;


  
  const [wasBgAudioPlaying] = useState(!isMuted);
  const onPopupAudioPlay = (e) => {
    handleAudioMute(true);
  }
  const onPopupAudioPaused = (e) => {
    if (wasBgAudioPlaying)
      handleAudioMute(false);
  }
  const onPopupCloseButtonClick = (e) => {
    if (wasBgAudioPlaying)
      handleAudioMute(false);
    handlePollenModelClose();
  }
  return (
    <div className="popup_wrap show-popup mypopup">
      <div>
        <div className="popup_head">
          <div className="blur"></div>
          <Container fluid>
            <button
              className="close_popup"
              onClick={onPopupCloseButtonClick}>
              CLOSE
              <img alt="close" src="./images/mobile/close_white.png" />
            </button>
          </Container>
        </div>
        <div className="popup_inner">
          <Container fluid>
            <h4
              dangerouslySetInnerHTML={{
                __html: mobileName ? mobileName : name,
              }}></h4>

            <Carousel images={images} />
            <div className="voice">
              <h5>
                Hear more about <br /> {name}
              </h5>
              <AudioPlayer
                className="audio-player"
                src={audio}
                onPlay={onPopupAudioPlay}
                onPause={onPopupAudioPaused}
              />
            </div>
          </Container>
          <div className="popup_info_wrap">
            <div className="lists">
              <Container>
                <div className="mb-4 d-flex flex-column">
                  <span>
                    <strong>Genus/species:</strong>
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: genus_species }}></span>
                </div>
                {synonyms.length ? (
                  <div className="mb-4 d-flex flex-column">
                    <span>
                      <strong>
                        {synonyms.includes(":") || synonyms.includes(",")
                          ? `Synonyms:`
                          : `Synonym:`}
                      </strong>
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: synonyms }}></span>
                  </div>
                ) : null}
                <div className="mb-4 d-flex flex-column">
                  <span>
                    <strong>Family:</strong>
                  </span>
                  <span>{family}</span>
                </div>
                <div className="mb-4 d-flex flex-column">
                  <span>
                    <strong>Distribution:</strong>
                  </span>
                  <span>{distributionMobile}</span>
                </div>
                <ul className="dotted_list">
                  {distributionMobileDetails.map((detail) => {
                    return detail.subDetails === null ? (
                      <li>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: detail.detail,
                          }}></div>
                      </li>
                    ) : (
                        <li>
                          <p>{`${detail.detail}:`}</p>
                          <ul className="sub_list">
                            {detail.subDetails.map((ele, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: ele.name,
                                  }}></div>

                                {ele.subDetailsArr !== null ? (
                                  <ul>
                                    {ele.subDetailsArr.map((sub, sindex) => (
                                      <li key={sindex}>{sub.name}</li>
                                    ))}
                                  </ul>
                                ) : null}
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                  })}
                </ul>
              </Container>
            </div>

            <div className="lists bg-gray">
              <Container fluid>
                <ul className="dotted_list">
                  {mobileComments.map((comment, index) => {
                    return (
                      <li key={index}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: comment.comment,
                          }}></div>
                        {comment.subComments !== null ? (
                          <ul>
                            {comment.subComments.map((sub, index) => (
                              <li key={index}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: sub,
                                  }}></div>
                              </li>
                            ))}
                          </ul>
                        ) : null}
                      </li>
                    );
                  })}
                </ul>
              </Container>
            </div>
            <div className="modal_btn">
            <MobileButton  mobile_url={button_url}/>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
