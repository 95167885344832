
/*comment out if EXE*/
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'airbnb-js-shims/target/es2015';
import 'airbnb-browser-shims/browser-only';
/*comment out if EXE*/


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';





//ReactDOM.render(<PollenModal />, document.getElementById('PollenModal'));
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
