import React from "react";

import PollenCard from "./PollenCard";

const Pollen = React.forwardRef((props, ref) => {
  const {
    currentStepPollenData,
    jsonDataObj,
    title,
    handlePollenModal,
    showPollenButtons,
  } = props;
  return (
    <div
      className={`pollen_wrap ${
        currentStepPollenData[0].shadow === "false" ? "header_shadow" : ""
      }`}
      ref={ref}
      id="pollenCard">
      <h3>{title}</h3>
      <div
        className={`pollen_cards ${
          showPollenButtons ? "pollenAnimate" : "pollenAnimate"
        }`}>
        {currentStepPollenData.map((pollen, idx) => {
          let pollenDetailObj = jsonDataObj.pollen.find(
            (pol) => pol.uid === pollen.uid
          );

          return (
            !!pollenDetailObj && (
              <PollenCard
                key={`pollen${idx}`}
                pollenDetails={pollenDetailObj}
                title={
                  pollenDetailObj.mobileName
                    ? pollenDetailObj.mobileName
                    : pollenDetailObj.name
                }
                imgPath={pollenDetailObj.thumb}
                handlePollenModal={handlePollenModal}
              />
            )
          );
        })}
      </div>
    </div>
  );
});
export default Pollen;
