import React from "react";
import PollenButton from "./PollenButton";
import MapMenu from "./MapMenu";
import HomeMapMenu from "./HomeMapMenu";
//import { Tween/*, Timeline*/ } from 'react-gsap';
import { TweenMax, TweenLite, Back, ExpoScaleEase, Sine, Linear } from "gsap";
// eslint-disable-next-line
import { DrawSVGPlugin } from "./libs/gsap/bonus-files-for-npm-users/DrawSVGPlugin";
import { Howl } from "howler";
import PollenModal from "./PollenModal";
import ReactDOM from "react-dom";

////////////////////////////////////////////////////////////////////
//if exe
//import SVG from './libs/react-inlinesvg-bono/react-inlinesvg-bono';
//else
import SVG from "react-inlinesvg";
/////////////////////////////////////////////////////////////////////

export default class Environment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevStep: 0,
      currentStep: 0,
      totalSteps: "",
      thisEnvData: this.props.thisEnvData,
      pollenData: this.props.pollenData,
      menuClick: true,
      soundPlaying: false,
    };

    this.currSound = {};
    this.otherSound = {};

    /*refs to children*/
    this.nextButton = React.createRef();
    //this.prevButton = React.createRef();
    this.vid = React.createRef();
    this.nav = React.createRef();
    this.pollenHolder = React.createRef();
    //this.loaderIndication = React.createRef();
    this.speaker = React.createRef();
    this.speakerHome = React.createRef();
    //this.mapMenu = React.createRef();
    this.topGradient = React.createRef();
    this.stagrLogo = React.createRef();

    /*refs components*/
    this.MapMenu_ref = React.createRef();

    /*methods to bind*/
    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.gotoStep = this.gotoStep.bind(this);
    this.vidScrub = this.vidScrub.bind(this);
    this.handleNavDisabled = this.handleNavDisabled.bind(this);
    this.showNav = this.showNav.bind(this);
    this.hideNav = this.hideNav.bind(this);
    this.playAudio = this.playAudio.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.refresh = this.refresh.bind(this);

    this.launchModal = this.launchModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getPollenInfoNode = this.getPollenInfoNode.bind(this);

    this.menuPath = this.menuPath.bind(this);

    this.buttonsEnabled = true;
    this.muted = false;
    this.homeMenuVidScrubDuration = 1;
    this.vidScrubDuration = 5; //make 5

    this.cirRot = "";

    this.iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    /*sound effects*/
    this.clickSound = new Howl({
      src: "./audio/interface/click.mp3",
      autoplay: false,
      loop: false,
      volume: 1,
    });
    this.clickSoundOut = new Howl({
      src: "./audio/interface/click-out.mp3",
      autoplay: false,
      loop: false,
      volume: 1,
    });

    TweenLite.ticker.fps(30);
  }

  /*componentWillMount(){

		this.setState({
			thisEnvData: this.props.thisEnvData,
			pollenData: this.props.pollenData,
			//currentStep:this.props.currentStep,
		});

	}*/
  /*componentWillReceiveProps(nextProps){}*/
  //shouldComponentUpdate(nextProps, nextState){}
  componentWillUpdate(nextProps, nextState) {
    //console.log('menuClick = '+nextState.menuClick);
    this.handleNavDisabled(nextState.currentStep);
    this.vidScrub(
      nextState.currentStep,
      this.vidScrubDuration,
      nextState.menuClick
    );
  }

  refresh() {
    window.location.reload(false);
  }

  handleNavDisabled(currStep) {
    //console.log('currStep = '+currStep);
    if (currStep > 1) {
      //this.prevButton.current.classList.remove("disabled");
    } else {
      //this.prevButton.current.classList.add("disabled");
    }

    if (currStep === this.state.thisEnvData.steps[0].length) {
      this.nextButton.current.classList.add("disabled");
    } else {
      this.nextButton.current.classList.remove("disabled");
    }
  }

  gotoStep(stepString, pollenID, menuClick) {
    const envHolder = document.getElementById("envHolder");
    envHolder.style.opacity = 1;

    if (!this.muted) this.clickSound.play();

    let step = Number(stepString);
    let currentStep = Number(this.state.currentStep);

    if (menuClick) {
      //alert('true');
      this.vidScrubDuration = 1;
    } else {
      //alert('false');
      this.vidScrubDuration = 5;
    }

    /*close the menu*/
    this.MapMenu_ref.current.closeMenu();

    if (this.buttonsEnabled === true) {
      //console.log('this.state.currentStep = '+currentStep);
      //console.log('step = '+step);

      /*nav or not*/
      if (step !== currentStep) {
        this.setState({
          currentStep: step,
          prevStep: step - 1,
          menuClick: menuClick,
        });
        this.buttonsEnabled = false;
        if (pollenID !== "") {
          let self = this;
          setTimeout(function () {
            //console.log('launch pollen DELAY');
            self.launchModal(pollenID);
          }, this.vidScrubDuration * 1000 + 1000);
        }
      } else {
        if (pollenID !== "") {
          //console.log('launch pollen NOW');
          this.launchModal(pollenID);
        } else {
          //console.log('nothing');
        }
      }
    }
  }

  nextStep(e) {
    this.gotoStep(this.state.currentStep + 1, "", false);
  }

  prevStep(e) {
    this.gotoStep(this.state.currentStep - 1, "", false);
  }

  showNav() {
    TweenMax.to(this.nav.current, 0.5, { display: "block", autoAlpha: 1 });
    document.getElementById("menuButton").classList.remove("disable");
  }

  hideNav() {
    //console.log('hiding nav');
    const self = this;
    TweenMax.to(this.nav.current, 0.5, {
      autoAlpha: 0,
      onComplete: function () {
        self.nav.current.style.display = "none";
      },
    });
    document.getElementById("menuButton").classList.add("disable");
  }

  showButtons() {
    /*TODO animate only the visibile buttons*/
    const pollenHolder = this.pollenHolder.current;
    TweenMax.set(pollenHolder.querySelectorAll(".pollenButtonHolder"), {
      autoAlpha: 0,
      scale: 0.5,
    });
    TweenMax.set(pollenHolder, { display: "block", autoAlpha: 1 });

    const visibleButtons = pollenHolder.querySelectorAll(
      '.pollenButtonHolder .pollenButton:not([style="display:none"])'
    );

    for (var i = visibleButtons.length - 1; i >= 0; i--) {
      //console.log('visible = '+i);
      //visibleButtons[i]
    }

    TweenMax.staggerTo(
      pollenHolder.querySelectorAll(".pollenButtonHolder"),
      0.5,
      { autoAlpha: 1, scale: 1, ease: Back.easeOut },
      0.2
    );
  }

  hideButtons() {
    const self = this;
    TweenMax.to(this.pollenHolder.current, 0.25, {
      autoAlpha: 0,
      onComplete: function () {
        self.pollenHolder.current.style.display = "none";
      },
    });
  }

  playAudio(step) {
    const self = this;
    setTimeout(function () {
      const currAudioStep = step - 1;
      const currStepAudioPath =
        self.state.thisEnvData.steps[0][currAudioStep].audio;
      if (!self.currSound.playing) {
        self.currSound = new Howl({
          src: [currStepAudioPath],
          autoplay: false,
          loop: true,
          volume: 1,
          pool: 3,
          onload: function () {
            let id1 = !self.muted
              ? self.currSound.play()
              : self.currSound.pause();
            if (!self.muted) {
              self.currSound.fade(0, 1, 5000, id1);
            }
          },
        });
      } else {
        if (!self.muted) {
          self.currSound.fade(1, 0, 5000);
        }

        self.currSound = new Howl({
          src: [currStepAudioPath],
          autoplay: false,
          loop: true,
          volume: 1,
          pool: 3,
          onload: function () {
            let id2 = !self.muted
              ? self.currSound.play()
              : self.currSound.pause();
            if (!self.muted) {
              self.currSound.fade(0, 1, 5000, id2);
            }
          },
        });
      }
    }, 200);
  }

  toggleMute() {
    const speaker = this.speaker.current;
    const speakerHome = this.speakerHome ? this.speakerHome.current : null;
    this.muted = this.muted === false ? true : false;

    if (!this.muted) this.clickSound.play();

    /*speaker.innerHTML = this.muted ? "SOUND IS OFF" : "SOUND IS ON";*/
    if (!speaker.classList.contains("muted")) {
      speaker.classList.remove("unmuted");
      speaker.classList.add("muted");
      this.currSound.pause();
    } else {
      speaker.classList.add("unmuted");
      speaker.classList.remove("muted");
      this.currSound.play();
    }

    //Home page speaker
    if (speakerHome) {
      if (!speakerHome.classList.contains("muted")) {
        speakerHome.classList.remove("unmuted");
        speakerHome.classList.add("muted");
        this.currSound.pause();
      } else {
        speakerHome.classList.add("unmuted");
        speakerHome.classList.remove("muted");
        this.currSound.play();
      }
    }
  }

  launchModal(pollenID) {
    if (!this.muted) this.clickSound.play();

    const thisPollenData = this.getPollenInfoNode(pollenID);

    /*target pollen buttons to add the class visited*/
    const pollenHolder = this.pollenHolder.current;
    const pollenButtonList = pollenHolder.getElementsByClassName(
      "pollenButton"
    );
    //console.log('pollenButtonList.length = '+pollenButtonList.length);
    for (var i = 0; i < pollenButtonList.length; i++) {
      //console.log('pollenButtonList[i][0].getAttribute("data-pollenid") = '+pollenButtonList[i].getAttribute("data-pollenid"));
      if (pollenButtonList[i].getAttribute("data-pollenid") === pollenID) {
        pollenButtonList[i].classList.add("visited");
      }
    }

    /*target SVG menu buttons to add the class visited*/
    const svgMenu_ref = this.props.svgMenu_ref.current;
    const items = svgMenu_ref.getElementsByClassName("item");

    for (var j = 0; j < items.length; j++) {
      //console.log('items[j][0].getAttribute("data-pollenid") = '+items[j].getAttribute("data-pollenid"));
      if (items[j].getAttribute("data-pollenid") === pollenID) {
        items[j].classList.add("visited");
      }
    }

    ReactDOM.render(
      <PollenModal
        muted={this.muted}
        show={true}
        onHide={this.closeModal}
        pollendatanode={thisPollenData}
      />,
      document.getElementById("PollenModal")
    );
  }

  getPollenInfoNode(uid) {
    const pollenData = this.state.pollenData;
    for (var i = 0; i < pollenData.length; i++) {
      if (pollenData[i].uid === uid) {
        return pollenData[i];
      }
    }
  }

  closeModal(e) {
    //console.log('hiding');
    if (!this.muted) this.clickSoundOut.play();
    ReactDOM.unmountComponentAtNode(document.getElementById("PollenModal"));
  }

  menuPath(pathGrowValue) {
    //console.log('menuPath pathGrowValue = '+pathGrowValue);
    const map = document.getElementById("MapMenu");
    if (map) {
      const path = map.querySelector(".path_grow");

      TweenLite.set(path, {
        drawSVG: pathGrowValue,
        ease: ExpoScaleEase.easeOut,
        delay: 1,
      });
    }
  }

  vidScrub(step, dur, menuClick) {
    //if(!this.muted){
    this.playAudio(step);

    //}
    //console.log('step  = '+step);

    /*update active state on svg map*/
    const map = document.getElementById("MapMenu");
    const steps = map.querySelectorAll(".step");
    for (var i = steps.length - 1; i >= 0; i--) {
      steps[i].classList.remove("active");
    }
    const active = steps[steps.length - step];
    const circle = steps[steps.length - step].querySelector("circle");
    active.classList.add("active");

    /*rotate active circle on svg menu*/
    //console.log('this.cirRot = '+this.cirRot);
    if (this.cirRot !== "") {
      this.cirRot.kill();
    }
    this.cirRot = TweenMax.to(circle, 6, {
      rotation: 360,
      repeat: -1,
      transformOrigin: "center center",
      ease: Linear.easeNone,
      overwrite: true,
    });

    this.hideButtons();
    this.hideNav();

    /*hide main menu if mounted*/
    const homeMenuHolder = document.getElementById("homeMenuHolder");
    if (window.getComputedStyle(homeMenuHolder).display === "block") {
      homeMenuHolder.style.display = "none";
      ReactDOM.unmountComponentAtNode(homeMenuHolder);
    }

    const time = this.state.thisEnvData.steps[0][step - 1].time;
    const pathGrowValue = this.state.thisEnvData.steps[0][step - 1]
      .pathGrowValue;

    let envVideoCurrentTime = this.vid.current.currentTime;

    let self = this;

    if (menuClick) {
      envVideoCurrentTime = time - 1;
    }
    //console.log('dur = ',dur);
    //TweenLite.lagSmoothing(100,33);
    TweenLite.fromTo(
      this.vid.current,
      dur,
      { currentTime: envVideoCurrentTime },
      {
        delay: 0.5,
        currentTime: time,
        ease: Linear.easeNone,
        overwrite: true,
        onStart: function () {
          /*to make IOS play the video*/
          self.vid.current.play();
          setTimeout(function () {
            self.vid.current.pause();
          }, 100);
          /*to make IOS play the video*/
          self.menuPath(pathGrowValue);
        },
        onComplete: function () {
          self.buttonsEnabled = true;
          self.showNav();
          self.showButtons();

          /*gradient logo update*/
          if (step === 1) {
            self.topGradient.current.classList.add("black");
            self.stagrLogo.current.setAttribute(
              "src",
              "./images/logo_white.svg"
            );
            document.getElementById("menuText__menuButton").style.fill =
              "#ffffff";
          } else {
            self.topGradient.current.classList.remove("black");
            self.stagrLogo.current.setAttribute("src", "./images/logo.svg");
            document.getElementById("menuText__menuButton").style.fill =
              "#003D5C";
          }
        },
      }
    );
  }

  render() {
    const pollenButtons = this.state.thisEnvData.steps[0].map((step, index) => {
      return step.pollen.map((pollen, index2) => {
        const getPollenInfoNode = this.getPollenInfoNode(pollen.uid);
        const modalClick = () => this.launchModal(pollen.uid);
        return (
          <PollenButton
            thisStepPollen={pollen}
            thisPollenData={getPollenInfoNode}
            key={index + index2}
            currentStep={this.state.currentStep}
            step={index + 1}
            launchModalHandler={modalClick}
            dataPollenId={pollen.uid}
          />
        );
      });
    });
    return (
      <div className="Environment">
        <video
          id="myEnvVideo"
          ref={this.vid}
          playsInline=""
          loop=""
          muted=""
          className=""
          preload=""
          // poster={ }
        >
          <source src={this.state.thisEnvData.video} type="video/mp4" />
        </video>
        <div className="top-gradient" ref={this.topGradient}></div>
        <div id="pollenHolder" ref={this.pollenHolder}>
          {pollenButtons}
        </div>
        <div id="navigation" ref={this.nav}>
          {/* <div id="prevButton" ref={this.prevButton} className="next nav" onClick={this.prevStep}>
	                	BACK
	                </div>*/}
          <div
            id="nextButton"
            ref={this.nextButton}
            className="prev nav"
            onClick={this.nextStep}>
            <SVG
              src="./images/next_nav.svg"
              uniqueHash="next_nav"
              uniquifyIDs={false}></SVG>
          </div>
        </div>
        <div id="PollenModal"></div>

        <div
          id="speaker"
          className={this.muted ? "muted" : "unmuted"}
          ref={this.speaker}
          onClick={this.toggleMute}>
          <img
            className="speakerBtn on"
            src="./images/speaker.svg"
            alt="speaker icon button on"></img>
          <img
            className="speakerBtn off"
            src="./images/speakerOff.svg"
            alt="speaker icon button off"></img>
        </div>
        <a href="https://www.stagrallergy.com" target="_blank" rel="noopener noreferrer">
        <img
          className="stagr-logo"
          src="./images/logo.svg"
          ref={this.stagrLogo}
          alt="Stallergenes logo" />
        </a>
        <div id="footer">
          &copy;Stallergenes Greer 2022. All Rights Reserved. 073119H2617 8.19
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.handleNavDisabled(this.state.currentStep);

    let self = this;

    /*load map*/
    ReactDOM.render(
      <MapMenu
        ref={this.MapMenu_ref}
        muted={this.muted}
        gotoStepHandler={this.gotoStep}
        refresh={this.refresh}
        onResize={this.props.onResize}
      />,
      document.getElementById("menuHolder")
    );

    /*load home menu*/
    ReactDOM.render(
      <HomeMapMenu
        ref={this.HomeMapMenu_ref}
        //loaderIndication={self.loaderIndication.current}
        gotoStepHandler={this.gotoStep}
        muted={this.muted}
        homeVideoPath={this.props.homeVideoPath}
        homeVideoPosterPath={this.props.homeVideoPosterPath}
        thisEnvData={self.state.thisEnvData}
        refresh={this.refresh}
        onResize={this.props.onResize}
        speaker={this.speakerHome}
        toggleMute={this.toggleMute}
      />,
      document.getElementById("homeMenuHolder")
    );
    TweenLite.to(self.props.loaderIndication.current, 1, {
      x: "100%",
      delay: 1,
      ease: Sine.easeIn,
      onComplete: function () {
        if (self.props.loaderIndication.current) {
          self.props.loaderIndication.current.style.display = "none";
        }
        //self.props.loaderIndication.current.parentNode.removeChild(self.props.loaderIndication.current);
      },
    });
    /*interval to check for video loaded*/
    let b = setInterval(() => {
      //console.log("checking...");

      if (self.vid.current.readyState >= 4 || this.iOS) {
        clearInterval(b);
        //console.log(" can play!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        //console.log("home audio = ",self.props.theData.menu[0].audio);

        //this.playAudio(0);

        self.currSound = new Howl({
          src: self.props.theData.menu[0].audio,
          //src: "/images/home-audio.mp3",
          autoplay: true,
          loop: true,
          volume: 1,
          pool: 3,
        });

        /*fade loader out*/
        TweenLite.to(self.props.loaderIndication.current, 1, {
          x: "100%",
          delay: 1,
          ease: Sine.easeIn,
          onComplete: function () {
            if (self.props.loaderIndication.current) {
              self.props.loaderIndication.current.style.display = "none";
            }
            //self.props.loaderIndication.current.parentNode.removeChild(self.props.loaderIndication.current);
          },
        });
      }
    }, 100);
  }
  componentDidUpdate(prevProps, prevState) {}
  componentWillUnmount() {
    this.currSound.pause();
  }
}

Environment.propTypes = {
  //prop: PropTypes.string.isRequired
};

Environment.defaultProps = {};

//ReactDOM.render( <Environment />, document.getElementById('Environment') );
